import React from 'react'
import Pawn from './Pacman'
import Ghost from './Ghost'
import Food from './Food'
import styled from 'styled-components'
import colors from '../styles/Colors'
import { useGameContext } from '../context/GameContext'
import { GAME_STATUS } from '../types/gameStatus'
import { DIFFICULTY, Difficulty } from '../types/difficulty'

import michal from 'src/assets/images/mike.jpg'
import mateusz from 'src/assets/images/matu.jpg'
import arek from 'src/assets/images/arek.jpg'
import asia from 'src/assets/images/asia.jpg'

type SceneProps = {
    foodSize: number
    border: number
    topScoreBoard: number
}

const knapmanSize = 60
const knapmanVelocity = 30
const ghostSize = 60
const topScoreBoardHeight = 100

const generateFoodMatrix = (props: SceneProps, amountOfFood: number) => {
    let currentTop = 0
    let currentLeft = 0
    const foods = []

    for (let i = 0; i <= amountOfFood; i++) {
        if (currentLeft + props.foodSize >= window.innerWidth - props.border) {
            currentTop += props.foodSize
            currentLeft = 0
        }
        if (
            currentTop + props.foodSize >=
            window.innerHeight - props.border - props.topScoreBoard
        ) {
            break
        }
        const position = { left: currentLeft, top: currentTop }
        currentLeft = currentLeft + props.foodSize
        foods.push(
            <Food
                knapmanSize={knapmanSize}
                hidden={false}
                name={'food' + i}
                position={position}
                key={i}
            />
        )
    }
    return foods
}

const Scene = (props: SceneProps) => {
    const {
        setFoodAmount,
        restartGame,
        setDifficulty,
        setGameStatus,
        foodAmount,
        gameStatus,
        difficulty,
    } = useGameContext()

    const [ghostVelocity, setGhostVelocity] = React.useState(20)

    React.useEffect(() => {
        if (difficulty === DIFFICULTY.EASY) {
            setGhostVelocity(15)
        }
        if (difficulty === DIFFICULTY.MEDIUM) {
            setGhostVelocity(20)
        }
        if (difficulty === DIFFICULTY.ADVANCED) {
            setGhostVelocity(30)
        }
    }, [difficulty])

    React.useEffect(() => {
        const amountOfFood =
            Math.floor((window.innerWidth - props.border) / props.foodSize) *
            Math.floor(
                (window.innerHeight - props.border - props.topScoreBoard) /
                    props.foodSize
            )

        setFoodAmount(amountOfFood)
    }, [])

    return (
        <StyledScene>
            {gameStatus !== GAME_STATUS.IN_PROGRESS &&
                gameStatus !== GAME_STATUS.PAUSED && (
                    <OverlayContent>
                        {gameStatus === GAME_STATUS.WON ? (
                            <CenterContainer>
                                <div>
                                    <strong>Congratulations :)</strong>
                                </div>
                                <StyledButton onClick={() => restartGame()}>
                                    Play again
                                </StyledButton>
                            </CenterContainer>
                        ) : (
                            <CenterContainer>
                                <div>
                                    <strong>GAME OVER :(</strong>
                                </div>
                                <StyledButton onClick={() => restartGame()}>
                                    Try Again
                                </StyledButton>
                            </CenterContainer>
                        )}
                    </OverlayContent>
                )}
            {gameStatus === GAME_STATUS.PAUSED && (
                <OverlayContent>
                    <CenterContainer>
                        <div>
                            <span>Set Difficulty</span>
                        </div>
                        <div>
                            <select
                                value={difficulty}
                                onChange={(e) =>
                                    setDifficulty(e.target.value as Difficulty)
                                }
                            >
                                <option value={DIFFICULTY.EASY}>Easy</option>
                                <option value={DIFFICULTY.MEDIUM}>
                                    Medium
                                </option>
                                <option value={DIFFICULTY.ADVANCED}>
                                    Advanced
                                </option>
                            </select>
                        </div>

                        <StyledButton
                            onClick={() =>
                                setGameStatus(GAME_STATUS.IN_PROGRESS)
                            }
                        >
                            Play!
                        </StyledButton>
                    </CenterContainer>
                </OverlayContent>
            )}
            {generateFoodMatrix(props, foodAmount)}
            <Pawn
                velocity={knapmanVelocity}
                size={knapmanSize}
                border={20}
                topScoreBoard={topScoreBoardHeight}
                name="knapman"
                image={arek}
            ></Pawn>
            <Ghost
                velocity={ghostVelocity}
                size={ghostSize}
                border={20}
                topScoreBoard={topScoreBoardHeight}
                image={michal}
                name="ghost1"
            ></Ghost>
            <Ghost
                velocity={ghostVelocity}
                size={ghostSize}
                border={20}
                topScoreBoard={topScoreBoardHeight}
                image={mateusz}
                name="ghost2"
            ></Ghost>
            {difficulty !== DIFFICULTY.EASY && (
                <Ghost
                    velocity={ghostVelocity}
                    size={ghostSize}
                    border={20}
                    topScoreBoard={topScoreBoardHeight}
                    image={asia}
                    name="ghost3"
                ></Ghost>
            )}
            {difficulty === DIFFICULTY.ADVANCED && (
                <Ghost
                    velocity={ghostVelocity}
                    size={ghostSize}
                    border={20}
                    topScoreBoard={topScoreBoardHeight}
                    image={michal}
                    name="ghost4"
                ></Ghost>
            )}
        </StyledScene>
    )
}

const CenterContainer = styled.div`
    position: absolute;
    margin: 0 auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 9999;
    background-color: ${colors.color2};
    color: ${colors.color3};
    padding: 20px;

    button {
        cursor: pointer;
    }
`

const OverlayContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    font-size: 40px;
`

const StyledScene = styled.div`
    --container-width: 100vw - 20px;
    height: calc(100vh - 120px);
    width: calc(var(--container-width));
    background-color: ${colors.color1};
    position: relative;
    border: 10px ${colors.color3} solid;
`

const StyledButton = styled.button`
    padding: 8px 16px;
    font-size: 24px;
    background-color: ${colors.color1};
    color: ${colors.color2};
    border: 1px ${colors.color3} solid;
    cursor: pointer;

    :hover {
        background-color: ${colors.color2};
        color: ${colors.color1};
    }
`

export default Scene
