import React from 'react'
import './Projects.css'
import { Link } from 'react-router-dom'

const Projects = () => {
    const links = [
        { to: '/toys/knapman', className: 'nav-article', text: 'Knapman' },
        { to: '/toys/calc', className: 'nav-article', text: 'Calculator' },
    ]

    return (
        <article>
            <h1 id="welcome-title">Welcome to my toys projects page.</h1>
            <p>Below, some of my playground projects in react.</p>
            <h2 id="list-of-projects">Contents</h2>
            <div>
                {links.map((link) => (
                    <nav>
                        <Link to={link.to} className={link.className}>
                            {link.text}
                        </Link>
                    </nav>
                ))}
            </div>
        </article>
    )
}

export default Projects
