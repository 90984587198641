import React from 'react'
import './Blog.css'
import { Link } from 'react-router-dom'

const Blog = () => {
    return (
        <article>
            <h1 id="welcome-title">Welcome to my blog.</h1>
            <p>
                Maybe I will write some articles here if I find an interesting
                topic to share.
            </p>
            <h2 id="list-of-articles">Contents</h2>
            <div>
                <nav>
                    <Link to="/blog/article-01.06.2024" className="nav-article">
                        Printing baby groot on creality k1 max.
                    </Link>
                </nav>
            </div>
        </article>
    )
}

export default Blog
