import React, { useState, useRef, MutableRefObject } from 'react'
import './Calc.css'

const App = () => {
    const inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null)
    const resultRef: MutableRefObject<HTMLInputElement | null> = useRef(null)
    const [result, setResult] = useState(0)

    function plus(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        setResult((result) => result + Number(inputRef?.current?.value))
    }

    function minus(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        setResult((result) => result - Number(inputRef?.current?.value))
    }

    function times(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        setResult((result) => result * Number(inputRef?.current?.value))
    }

    function divide(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        setResult((result) => result / Number(inputRef?.current?.value))
    }

    function resetInput(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        if (inputRef?.current?.value) {
            inputRef.current.value = ''
        }
    }

    function resetResult(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault()
        setResult(0)
    }

    return (
        <div className="App">
            <div>
                <h1>Calculator</h1>
            </div>
            <form>
                <p ref={resultRef}>{result}</p>
                <input
                    pattern="[0-9]"
                    ref={inputRef}
                    type="number"
                    placeholder="Type a number"
                />
                <button onClick={plus}>add</button>
                <button onClick={minus}>subtract</button>
                <button onClick={times}>multiply</button>
                <button onClick={divide}>divide</button>
                <button onClick={resetInput} className="btn1">
                    reset input
                </button>
                <button onClick={resetResult} className="btn2">
                    reset result
                </button>
            </form>
        </div>
    )
}

export default App
