export type Position = {
    top: number
    left: number
}

export const ghostStartPosition: Position = {
    top: 300,
    left: 300,
}

export const knapmanStartPosition: Position = {
    top: 0,
    left: 0,
}
