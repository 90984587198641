import React from 'react'
import '../.././Blog.css'
import ReactPlayer from 'react-player'

const Article = () => {
    return (
        <article>
            <h1 id="article-title">Printing baby groot on creality k1 max.</h1>
            <p>
                I found this nice baby groot model on thingiverse and printed it
                at home with a creality k1 max 3D-printer.
                <ReactPlayer
                    url="https://youtube.com/shorts/nZG1Bg5qiT4?feature=share"
                    volume={0.1}
                />
            </p>
        </article>
    )
}

export default Article
