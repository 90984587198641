import Header from './components/Header'
import Scene from './components/Scene'
import { GameProvider } from './context/GameContext'
import './PackmanApp.css'

const PackmanApp = () => {
    return (
        <div className="knapman-app">
            <GameProvider>
                <Header />
                <Scene foodSize={60} border={20} topScoreBoard={100} />
            </GameProvider>
        </div>
    )
}

export default PackmanApp
