import React from 'react'
import './Home.css'

const Home = () => {
    return (
        <article>
            <h1 id="hi-im-arkadiusz-knapik">Hi, I’m Arkadiusz Knapik.</h1>
            <p>
                I’m a software engineer.
                <br></br>
                You can check out some of my work experience on
                <a
                    id="link-to-linkedin"
                    href="https://www.linkedin.com/in/arkadiusz-knapik/"
                    target="_blank"
                >
                    my linkedin profile.
                </a>
            </p>

            <h2 id="contact-me">Contact me</h2>
            <p>Linkedin is generally the best way to contact me.</p>

            <h2 id="about-this-website">About this website</h2>
            <p>
                I build this website with react and host it on azure using
                github workflows automation.
            </p>
        </article>
    )
}

export default Home
