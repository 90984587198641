import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Position } from '../types/position'
import { ARROW, DIRECTION, Direction } from '../types/direction'
import { Character } from '../types/character'
import { useGameContext } from '../context/GameContext'
import { useInterval } from '../hooks/useInterval'
import { GAME_STATUS } from '../types/gameStatus'
import colors from '../styles/Colors'
import arek from 'src/assets/images/arek.jpg'

interface StyledknapmanProps {
    direction: Direction
    position: Position
    isAlive: boolean
}

type knapmanMouthProps = {
    moving: boolean
}

const Pawn = (props: Character) => {
    const {
        pawnPosition: position,
        setPawnPosition,
        gameStatus,
    } = useGameContext()
    const [direction, setDirection] = React.useState<Direction>(DIRECTION.RIGHT)
    const [lastTouched, setLastTouched] = React.useState<Touch>()
    useInterval(move, 100)

    React.useEffect(() => {
        function rotate(keypressed: number) {
            switch (keypressed) {
                case ARROW.LEFT:
                    setDirection(DIRECTION.LEFT)
                    break
                case ARROW.UP:
                    setDirection(DIRECTION.UP)
                    break
                case ARROW.RIGHT:
                    setDirection(DIRECTION.RIGHT)
                    break
                default:
                    setDirection(DIRECTION.DOWN)
            }
        }

        function handleKeyDown(e: any) {
            const arrows = [ARROW.LEFT, ARROW.UP, ARROW.RIGHT, ARROW.DOWN]

            if (arrows.indexOf(e.keyCode) >= 0) {
                rotate(e.keyCode)
            }
        }

        function handleTouchStart(e: TouchEvent) {
            for (let i = 0; i < e.touches.length; i++) {
                const touch = e.touches.item(i)
                if (touch) {
                    setLastTouched(touch)
                }
            }
        }

        document.addEventListener('touchstart', handleTouchStart)
        document.addEventListener('keydown', handleKeyDown, false)
        document.addEventListener('restart-game', gameRestarted)

        return () => {
            document.removeEventListener('restart-game', gameRestarted)
            document.removeEventListener('keydown', handleKeyDown)
            document.removeEventListener('touchstart', handleTouchStart)
        }
    }, [])

    React.useEffect(() => {
        if (lastTouched && position) {
            let diffLeft = lastTouched.clientX - position.left
            let diffTop = lastTouched.clientY - position.top
            if (Math.abs(diffLeft) > Math.abs(diffTop)) {
                if (diffLeft > 0) {
                    setDirection(DIRECTION.RIGHT)
                } else {
                    setDirection(DIRECTION.LEFT)
                }
            } else {
                if (diffTop > 0) {
                    setDirection(DIRECTION.DOWN)
                } else {
                    setDirection(DIRECTION.UP)
                }
            }
        }
    }, [lastTouched])

    function gameRestarted() {
        /*setColor(props.color);*/
    }

    function move() {
        if (gameStatus === GAME_STATUS.IN_PROGRESS) {
            const currentLeft = position.left
            const currentTop = position.top
            let newPosition: Position = { top: 0, left: 0 }
            switch (direction) {
                case DIRECTION.LEFT:
                    newPosition = {
                        top: currentTop,
                        left: Math.max(currentLeft - props.velocity, 0),
                    }
                    break
                case DIRECTION.UP:
                    newPosition = {
                        top: Math.max(currentTop - props.velocity, 0),
                        left: currentLeft,
                    }
                    break
                case DIRECTION.RIGHT:
                    newPosition = {
                        top: currentTop,
                        left: Math.min(
                            currentLeft + props.velocity,
                            window.innerWidth - props.border - props.size
                        ),
                    }
                    break

                default:
                    newPosition = {
                        top: Math.min(
                            currentTop + props.velocity,
                            window.innerHeight -
                                props.size -
                                props.border -
                                props.topScoreBoard
                        ),
                        left: currentLeft,
                    }
            }
            setPawnPosition(newPosition)
        }
        if (gameStatus === GAME_STATUS.LOST) {
            /*setColor(COLOR.knapman_DEAD);*/
        }
    }

    return (
        <Styledknapman
            tabIndex={0}
            /*color={color}*/
            position={position}
            direction={direction}
            isAlive={gameStatus !== GAME_STATUS.LOST}
        >
            {/*<knapmanEye />*/}
            <img src={arek} alt="packman" />
            {/*<knapmanMouth moving={gameStatus === GAME_STATUS.IN_PROGRESS} />*/}
        </Styledknapman>
    )
}

const eat = keyframes`
    0% {
        clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
    }
    25% {
        clip-path: polygon(100% 60%, 44% 48%, 100% 35%);
    }
    50% {
        clip-path: polygon(100% 50%, 44% 48%, 100% 60%);
    }
    75% {
        clip-path: polygon(100% 59%, 44% 48%, 100% 35%);
    }
    100% {
        clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
    }
`

const Styledknapman = styled.div<StyledknapmanProps>`
    width: 60px;
    height: 63px;
    position: absolute;
    top: ${(props) => props.position.top}px;
    left: ${(props) => props.position.left}px;
    transform: ${(props) => {
        switch (props.direction) {
            case DIRECTION.LEFT:
                return 'rotateY(180deg)'
            case DIRECTION.UP:
                return 'rotate(-90deg)'
            case DIRECTION.DOWN:
                return 'rotate(90deg)'
            default:
                return 'rotate(0deg)'
        }
    }};
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: transparent; /*${(props) =>
        props.isAlive ? colors.color2 : 'white'};*/
    position: relative;
`

const knapmanEye = styled.div`
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    top: 10px;
    right: 26px;
    background: ${colors.color1};
`

const knapmanMouth = styled.div<knapmanMouthProps>`
    animation-name: ${eat};
    animation-duration: 0.7s;
    animation-iteration-count: ${(props) =>
        props.moving ? 'infinite' : 'initial'};
    background: ${colors.color1};
    position: absolute;
    width: 100%;
    height: 100%;
    clip-path: polygon(100% 74%, 44% 48%, 100% 21%);
`

export default Pawn
